import { Pipe, PipeTransform } from '@angular/core';
import { RefundRequestStatus } from '@roadmate/roadmate-common';
import { cilXCircle, cilCheckCircle, cilAvTimer, cilZoom} from '@coreui/icons'

@Pipe({
  name: 'rm_refund_icon',
  standalone: true
})
export class RefundStatusPipe implements PipeTransform {
  transform(value: RefundRequestStatus): any {
    switch (value) {
      case RefundRequestStatus.DECLINED:
        return cilXCircle;
      case RefundRequestStatus.DONE:
        return cilCheckCircle;
      case RefundRequestStatus.PENDING:
        return cilAvTimer;
      case RefundRequestStatus.PROCESSING:
        return cilZoom;
    }
  }
}

@Pipe({
  name: 'rm_refund_icon_color',
  standalone: true
})
export class RefundStatusColorPipe implements PipeTransform {
  transform(value: RefundRequestStatus): string {
    switch (value) {
      case RefundRequestStatus.DECLINED:
        return 'red';
      case RefundRequestStatus.DONE:
        return 'green';
      case RefundRequestStatus.PENDING:
        return 'orange';
      case RefundRequestStatus.PROCESSING:
        return 'rm-blue';
    }
    return 'grey';
  }
}

@Pipe({
  name: 'rm_refund_status_tooltip',
  standalone: true
})
export class RefundStatusToolTipPipe implements PipeTransform {
  transform(value: RefundRequestStatus): string {
    switch (value) {
      case RefundRequestStatus.DECLINED:
        return 'requeust_declined';
      case RefundRequestStatus.DONE:
        return 'request_processes_successfully';
      case RefundRequestStatus.PENDING:
        return 'request_pending_approval';
      case RefundRequestStatus.PROCESSING:
        return 'request_pending_user_action';
    }
    return '';
  }
}

@Pipe({
  name: 'rm_refund_badge_color',
  standalone: true
})
export class RefundStatusBadgeColorPipe implements PipeTransform {
  transform(value: RefundRequestStatus): string {
    switch (value) {
      case RefundRequestStatus.DECLINED:
        return 'danger';
      case RefundRequestStatus.DONE:
        return 'success';
      case RefundRequestStatus.PENDING:
        return 'warning';
      case RefundRequestStatus.PROCESSING:
        return 'info';
      case RefundRequestStatus.CANCELED:
        return 'dark';
    }
    return 'primary';
  }
}